import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#FFFFFF", 
            secondary: "#1E5279", 
            accent: "#FFFFFF",
          },
        },
      },
});
