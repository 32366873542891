import Vue from 'vue'
import VueRouter from 'vue-router'
import AgeGate from '../views/AgeGate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/age-gate',
    name: 'AgeGate',
    component: AgeGate
  },
  {
    path: '/',
    name: 'Menu',
    component: () => import('../views/Menu.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/leaflet',
    name: 'Leaflet',
    component: () => import('../views/Leaflet.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/how-to-use',
    name: 'HowToUse',
    component: () => import('../views/HowToUse.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/educational-videos',
    name: 'EducationalVideos',
    component: () => import('../views/EducationalVideos.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/e-presenter',
    name: 'Epresenter',
    component: () => import('../views/Epresenter.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/competition',
  //   name: 'Competition',
  //   component: () => import('../views/Game.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   }
  // },
  {
    path: '/product-range',
    name: 'Product Range',
    component: () => import('../views/ProductRange.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/Video.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !localStorage.ageCheck) {
    next("/age-gate");
  } else if (!requiresAuth && localStorage.ageCheck) next("/");
  else next();
});

export default router
