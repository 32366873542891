import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueGtm from "vue-gtm";
import router from './router'
//import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
//Vue.use(VueReCaptcha, { siteKey: '6LfZGlsdAAAAAF5hp_rmc7c_AHaHXvK51a54patf' });
Vue.use(VueGtm, {
  id: "GTM-5FJQWMH",
  //id: "GTM-NF8G7TN",
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
