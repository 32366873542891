<template>
  <v-app id="app">
    <Header />
    <v-main>
      <!-- <LeftHex v-if="$route.name !== 'Competition'" /> -->
      <router-view />
      <!-- <RightHex v-if="$route.name !== 'Competition'" /> -->
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
// import LeftHex from "./components/LeftHex";
// import RightHex from "./components/RightHex";

export default {
  components: {
    Header,
    Footer,
    // LeftHex,
    // RightHex,
  },
  mounted() {
    if (!localStorage.dialog) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";

.v-main {
  padding-bottom: 0 !important;
  z-index: 1;
}

.theme--light.v-application {
  background: none !important;
  font-family: $primary-font, Arial, Helvetica, sans-serif !important;
}

.grecaptcha-badge {
  z-index: 0;
  bottom: 38px !important;
}

.slick-next,
.slick-prev {
  font-size: 0;
  z-index: 10;
  cursor: pointer;
}

.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-next::before {
  content: url("./assets/images/right-arrow.png") !important;
}

.slick-prev::before {
  content: url("./assets/images/left-arrow.png") !important;
}
</style>
