<template>
  <v-footer color="primary" padless fixed class="p-1">
    <v-col class="d-flex align-center pa-2" cols="12">
      <img
        class="eighteen-over"
        src="../assets/images/eighteen-plus-primary.png"
        alt="This product contains nicotine. Nicotine is an addictive substance"
      />
      <v-spacer></v-spacer>
      <p class="pa-0 ma-0 text-right d-flex align-center">
        This website is for tobacco and nicotine retailers only retailers only.
      </p>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.5rem;
  color: #003b71 !important;
}

.eighteen-over {
  width: 200px;
}

@media only screen and (max-width: 768px) {
  @media only screen and (max-height: 500px) {
    .footer {
      height: 10vh;
    }
  }
  @media only screen and (max-height: 450px) {
    .eighteen-over {
      width: 150px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .eighteen-over {
    width: 100px;
  }
  .footer {
    height: 5vh;
  }
  p {
    font-size: 5px;
    line-height: 0.4rem;
  }
  .br-hexagon {
    bottom: 30px;
  }
}
@media only screen and (max-width: 340px) {
  p {
    font-size: 4px;
    line-height: 0.3rem;
  }
}
</style>
