<template>
  <v-app-bar app color="primary" dark flat>
    <v-spacer></v-spacer>
    <div class="d-flex">
      <router-link
        to="/"
        class="d-flex justify-center align-center"
        style="height: 100px"
      >
        <img
          class="nordic-logo"
          src="../assets/images/nordic-spirit-logo.png"
          alt="Nordic Spirit"
        />
      </router-link>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
.nordic-logo {
  width: 260px;
}

@media only screen and (max-width: 575px) {
  .nordic-logo {
    width: 200px;
  }
}
</style>
