<template>
  <v-container>
    <v-row class="age-gate row justify-content-center">
      <v-col class="age-gate-header" cols="12">
        <h1>
          TO PROCEED PLEASE
          <br />
          CONFIRM YOU ARE 18+ YEAR
          <br />
          OLD AND A MEMBER OF
          <br />
          THE IRISH TOBACCO/NICOTINE TRADE
        </h1>
      </v-col>
      <v-col>
        <v-form class="age-gate-form" ref="form" v-model="valid">
          <div class="all-boxes">
            <div class="form-box-containers over-eighteen-box align-top">
              <v-checkbox
                v-model="checkbox1"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                label="I AM 18 YEARS OR OLDER"
                color="#003B71"
                value="over18"
                hide-details
                on-icon="mdi-circle"
                off-icon="mdi-circle"
                dark
                required
              ></v-checkbox>
            </div>
            <div class="form-box-containers tobacco-trade-box align-top">
              <v-checkbox
                v-model="checkbox2"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                label="I AM A MEMBER OF THE TOBACCO/NICOTINE TRADE"
                color="#003B71"
                value="tobaccoMember"
                hide-details
                on-icon="mdi-circle"
                off-icon="mdi-circle"
                dark
                required
              ></v-checkbox>
            </div>
            <div class="form-box-containers tobacco-trade-box align-top">
              <v-checkbox
                v-model="checkbox3"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                label="I HAVE READ AND AGREED TO THE PRIVACY POLICY AND TERMS AND CONDITIONS"
                color="#003B71"
                value="tobaccoMember"
                hide-details
                on-icon="mdi-circle"
                off-icon="mdi-circle"
                dark
                required
              ></v-checkbox>
            </div>
          </div>

          <div class="continue-button-border">
            <v-btn class="continue-btn" :disabled="!valid" @click="validate">
              CONTINUE
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AgeGate",

  data: () => ({
    valid: true,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();

      if (this.valid) {
        localStorage.ageCheck = true;
        this.$router.push({ name: "Menu" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";

.age-gate {
  color: #003b71;
  height: 90vh;
  align-content: center;

  .v-icon.v-icon {
    font-size: 50px !important;
  }

  .v-label {
    left: 5px !important;
    color: #003b71 !important;
  }
}

.age-gate-header > h1 {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  font-family: "Gotham Bold", "Helvetica", "Arial", sans-serif !important;
}

.age-gate-form {
  margin-top: 30px;
}
.ts-cs-box {
  margin-top: -10px;
}

.error-message {
  height: 18px;
  color: rgb(179, 0, 0);
}

.fade-out {
  animation: fadeOut 1s forwards;
}

.all-boxes {
  display: flex;
  justify-content: center;

  // ensure all children are spaced evenly and are the same size
  > * {
    flex: 1;
    margin: 0 10px;
  }
}

.form-box-containers {
  display: flex;
  margin: 40px 20px;
  // justify-content: center;
  align-items: center;
}

.continue-button-border {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px !important;
  width: 400px;
  display: flex;
  justify-content: center;

  * {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.continue-btn {
  font-weight: bold;
  font-size: 1.25rem !important;
  line-height: 1.25rem !important;
  width: inherit;
  background-color: #003b71 !important;
  color: #ffffff !important;
  // clip path for button as a rectangle with circlar corners with a 50% radius
  padding: 5px 20px !important;
  font-family: "NimbusSanConD", Arial, sans-serif;
  height: unset !important;
  border-radius: 20px !important;
  width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .v-btn__content {
    position: relative;
    top: 2px;
    font-size: 1.35rem;
  }
}

.custom-checkbox-container {
  position: relative;
  padding-left: 50px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  height: 40px;
  width: 40px;
  background-color: #003b71;
}

.checkmark-two {
  margin-top: -8px;
}
.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #40aad7;
}

.custom-checkbox-container input:checked ~ :after {
  display: block;
}

.custom-checkbox-container input:checked ~ .checkmark {
  background-color: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container .checkmark:after {
  left: 10px;
  top: 11px;
  width: 18px;
  height: 18px;
  border: solid #ffffff;
  border-width: 10px 10px 9px 10px;
  /* transform: rotate(45deg);*/
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.portrait-only {
  display: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* MEDIA QUERIES */
@media only screen and (max-width: 992px) {
  .age-gate-header > h1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
  }

  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
  }
  .continue-button-border {
    width: 350px;
    font-size: 0.9rem;
  }
  @media only screen and (max-height: 414px) {
    .portrait-only {
      display: block;
      width: 100vw;
      background-image: linear-gradient(#004671, #40aad7);
      height: 100vh;
      position: absolute;
      top: 0;
      z-index: 999;
    }
    .portrait-only > h1 {
      margin-top: 150px;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 768px) {
  .all-boxes {
    display: block;
  }
  .age-gate-form {
    margin-top: 10px;
    font-size: 1rem;
  }
  .form-box-containers {
    margin: 20px auto 20px 10px;
  }

  .continue-button-border {
    margin-top: -20px;
    width: 300px;
  }
  .continue-btn {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 670px) {
  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
  }
  .form-box-containers {
    margin: 20px auto;
  }
  .checkmark-two {
    margin-top: -10px;
  }
  .continue-button-border {
    margin-top: -10px;
  }
}
@media only screen and (max-width: 575px) {
  .age-gate {
    height: 80vh;
  }
  .age-gate-header h1 {
    font-size: 1rem;
  }
  .age-gate-form {
    margin-top: 20px;
    font-size: 0.8rem;
  }
  .form-box-containers {
    margin: 5px auto;
    padding: 0 0px;
  }
  .checkmark {
    height: 25px;
    width: 25px;
  }
  .checkmark-one {
    margin-top: -4px;
  }
  .checkmark-two {
    margin-top: -4px;
  }
  .checkmark-three {
    margin-top: 10px;
  }
  .custom-checkbox-container {
    padding-left: 30px;
  }
  .custom-checkbox-container .checkmark:after {
    left: 4.85px;
    top: 4.5px;
    width: 16px;
    height: 16px;
    border-width: 8px 1px 8px 1px;
  }
  .continue-button-border {
    margin-top: -10px;
    width: 310px;
  }
  .continue-btn {
    font-size: 0.6rem;
    padding: 20px 0;
  }
}
@media only screen and (max-width: 414px) {
  .age-gate-header h1 {
    font-size: 1rem;
  }
  .age-gate .v-label {
    font-size: 0.8rem !important;
    font-family: $primary-font;
    margin-top: 0;
    max-width: 80vw;
  }
  .form-box-containers {
    margin: 5px auto;
    padding: 0 30px;
  }
  .checkmark {
    height: 25px;
    width: 25px;
  }
  .checkmark-one {
    margin-top: -5px;
  }
  .checkmark-two {
    margin-top: -5px;
  }
  .checkmark-three {
    margin-top: 10px;
  }
  .custom-checkbox-container {
    padding-left: 30px;
  }
  .custom-checkbox-container .checkmark:after {
    left: 4.85px;
    top: 4.5px;
    width: 16px;
    height: 16px;
    border-width: 8px 1px 8px 1px;
  }
  .continue-button-border {
    margin-top: -10px;
  }
  .continue-btn {
    font-size: 0.6rem;
    padding: 20px 0;
  }
}
@media only screen and (max-width: 340px) {
  .continue-button-border {
    width: 180px;
  }
  .age-gate-header h1 {
    font-size: 0.9rem;
  }
  .age-gate-form {
    font-size: 0.6rem;
  }
}
</style>
